html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Arial";
}

a {
  color: inherit;
  text-decoration: none;
}

.MuiDrawer-paper {
  top: 97px !important;
}

.MuiTypography-body1 {
  font-size: 15px !important;
  font-family: Arial !important;
}

.typo-font-weight.css-ahj2mt-MuiTypography-root>span {
  font-family: 600 !important;
  font-family: Arial !important;
}

.css-a1espa {
  background: #F0F0F0 0% 0% no-repeat padding-box !important;
  font: normal normal bold 14px/22px Arial !important;
  letter-spacing: 0.06px !important;
  color: #3D3D3D !important;
  border-bottom: none !important;
}

.MuiListItem-root.Mui-selected .MuiTypography-body1 {
  font-size: 15px !important;
  font-weight: 600;
  font-family: Arial !important;
}

.drawer-closed>div>div>ul>a>div>div.MuiListItemText-root {
  display: none;
}

.drawer-opened .drawer-icon {
  float: left !important;
}

.typo-font-weight.css-ahj2mt-MuiTypography-root>span {
  font-family: 600 !important;
}

.main-pagination-tag .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: "#E64934 !important";
}

.drawer-closed,
.drawer-opened {
  position: fixed;
}

.drawer-closed+main.main-component {
  margin-left: 65px;
  width: calc(100% - 65px);
  min-height: 530px;
}

.drawer-opened+main.main-component {
  margin-left: 230px;
  width: calc(100% - 240px);
  min-height: 530px;
}

.drawer-closed .sideber-options {
  padding: 17.3px 13px;
  text-align: center;
  margin-top: 0;
}

.drawer-opened .sideber-options {
  padding: 13px 13px;
  margin-top: 0;
}

.footer-content {
  background-color: "#EAEAEA" !important;
  color: "#ffffff" !important;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.drawer-closed+.footer-content {
  margin-left: 65px;
  width: calc(100% - 65px);
}

.drawer-opened+.footer-content {
  margin-left: 220px;
  width: calc(100% - 240px);
}

div.sideber-options:hover>div>svg>path,
div.sideber-options:hover>div>svg>g>path,
div.sideber-options.Mui-selected>div>svg>path,
div.sideber-options.Mui-selected>div>svg>g>path {
  fill: #00a651;
}

.MuiTypography-root {
  font-family: Arial !important;
}

.MuiListItem-root.Mui-selected .border-sloid1 {
  width: 5px;
  height: 30px;
  background-color: #00a651 !important;
}

.MuiListItem-root.Mui-selected .MuiTypography-body1 {
  font-size: 15px !important;
  font-weight: 600;
}

a:hover {
  color: #5f5f5f !important;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.css-1th0clu-MuiTableCell-root.MuiTableCell-head {
  background: #F0F0F0 0% 0% no-repeat padding-box !important;
  font: normal normal bold 14px/22px Arial !important;
  letter-spacing: 0.06px !important;
  color: #3D3D3D !important;
  border-bottom: none !important;
}

.css-11etw4a-MuiTableCell-root.MuiTableCell-body {
  font: normal normal normal 14px/22px Arial !important;
  letter-spacing: 0.06px !important;
  color: #606060 !important;
  background-color: #F0F0F0 !important;
  border-bottom: none !important;
}

.css-1dor8x2 {
  background-color: #F0F0F0 !important;
  border-bottom: none !important;
}

.css-1aj44tz {
  background-color: #F0F0F0 !important;
  border-bottom: none !important;
}

.css-41wjij-MuiTableCell-root.MuiTableCell-body {
  background-color: #F0F0F0 !important;
}

.fontLabel {
  color: rgba(0, 0, 0, 0.38) !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.MuiIconButton-root {
  border-radius: none !important;
  border: none !important;
  background-color: none !important;
}

/* .MuiIconButton-root:hover {
  background-color: #ffffff !important;
  
} */

.MuiIconButton-root:focus-visible {
  border-radius: none !important;
  border: none !important;
  background-color: none !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  text-transform: capitalize !important;
}

.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  text-transform: capitalize !important;
}

.css-1hw9j7s {
  text-transform: capitalize !important;
}

.css-79xub {
  text-transform: capitalize !important;
}

.icon2{
  text-align: right;
}

.text-left{
  text-align:left !important
}